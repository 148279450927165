export const SocialMediaPlatform = {
  TWITTER: "TWITTER",
  LINKEDIN: "LINKEDIN"
};

export const AnalyticsScreen = {
  HOME: "home"
};

export const AnalyticsEvents = {
  SOCIALMEDIA: "socialMediaPostView"
};

export const SponsorTypes = {
  GOLD: 1,
  SILVER: 2,
  BRONZE: 3,
  MEDIA: 4
};

export const CountDownTimer = {
  DAYS: "DAYS",
  HOURS: "HOURS",
  MINUTES: "MINUTES",
  SECONDS: "SECONDS"
};

export const HubSpotFormName = {
  SPONSOR: "SPONSOR",
  GET_TICKET: "GET_TICKET",
  APPLY_TO_SPEAK: "APPLY_TO_SPEAK",
  CO_LOCATED_EVENTS: "CO_LOCATED_EVENTS"
};

// export const HubspotFormProductionId = {
//   SPONSOR: "89d32b61-aed4-4eb1-9dd2-bfac90dc938d",
//   GET_TICKET: "03626cec-d8a8-4f90-b7bc-64c96ee38c04",
//   APPLY_TO_SPEAK: "ee9cbbd1-4e67-4f0c-af37-d2bebc7bf2d0",
//   CO_LOCATED_EVENTS: "343472e6-3a5f-4f01-ab48-d4536dedacbc"
// };

// // for all pre-prod sites
// export const HubspotFormStagingId = {
//   SPONSOR: "d46bd97d-b628-437b-8ca8-b186d948d645",
//   GET_TICKET: "384342d9-bfcb-4769-9073-e87b7ed268b7",
//   APPLY_TO_SPEAK: "88b1619b-db00-4c7b-a044-2c22e5e9d7bb",
//   CO_LOCATED_EVENTS: "d4e132af-4380-40d7-bbd7-5ac62c2f72a2"
// };

export const ParticipantSubRole = {
  BUYER: "BUYER"
};
