import React from "react";
import FM_LINKS from "routes";
import "../src/fintech_styles.scss";
import Home from "fintech/pages/home";
import Header from "components/Header";
import Footer from "components/Footer";
import queryString from "query-string";
import Hotel from "fintech/pages/hotel";
import Cookies from "fintech/pages/cookies";
import Sponsors from "fintech/pages/sponsors";
import Schedule from "fintech/pages/schedule";
import { useCopy } from "utility/copyContext";
import Speakers from "fintech/pages/speakers";
import { AnalyticsEvents } from "helpers/enums";
import TicketsPage from "fintech/pages/tickets";
import Agenda from "fintech/pages/static/agenda";
import WaitListForm from "components/WaitListForm";
import ModalText from "components/ModalText";
import { getBase64Decoded } from "./helpers/utils";
import SpeakerForm from "fintech/pages/speakerForm";
import SponsorForm from "fintech/pages/sponsorForm";
import TermsOfUse from "fintech/pages/static/terms-of-use";
import CoLocatedEvents from "fintech/pages/coLocatedEvents";
import ApplyToSpeakForm from "fintech/pages/applyToSpeakForm";
import CoLocatedEventsForm from "fintech/pages/coLocatedEventsForm";
import { AnalyticsScreen, SocialMediaPlatform } from "helpers/enums";
import { Routes, Route, Navigate, useNavigate } from "react-router-dom";
import { isMacOS, isWindowsOS, logEventInAnalytics } from "helpers/utils";
import TermsAndConditions from "fintech/pages/static/terms-and-conditions";
import IndividualSpeaker from "fintech/pages/speakers/components/individualSpeaker";
import HostedTermsAndConditions from "fintech/pages/static/hosted-terms-and-conditions";
import ExhibitorRulesAndRegulations from "fintech/pages/static/exhibitor-rules-and-regulations";
import TermsOfAttendanceAndParticipation from "fintech/pages/static/terms-of-attendance-and-participation";
import { Feedback } from "fintech/pages/feedback";

function App() {
  const navigate = useNavigate();
  const { generalStr } = useCopy();
  const parsedQueryStringObj = queryString.parse(window.location.search);
  const [openWaitListForm, setOpenWaitListForm] = React.useState(false);
  const [openModalText, setOpenModalText] = React.useState(false);

  React.useEffect(() => {
    if (parsedQueryStringObj.postRef && parsedQueryStringObj.source) {
      const participantId = getBase64Decoded(parsedQueryStringObj.postRef);
      const parsedSource = getBase64Decoded(parsedQueryStringObj.source);
      if (!participantId || !parsedSource) {
        return;
      }
      const socialMediaSource = SocialMediaPlatform[parsedSource.toUpperCase()] || "";
      const logParams = {
        eventID: generalStr.eventId,
        eventDate: new Date().toDateString(),
        participantID: participantId,
        target: socialMediaSource,
        screenName: AnalyticsScreen.HOME
      };
      logEventInAnalytics(AnalyticsEvents.SOCIALMEDIA, logParams);
      delete parsedQueryStringObj.postRef;
      delete parsedQueryStringObj.source;
      navigate({ search: queryString.stringify({ ...parsedQueryStringObj }) }, { replace: true });
    }
  }, [generalStr.eventId, parsedQueryStringObj, navigate]);

  if (isMacOS()) {
    document.body.classList.add("isMacOS");
  }
  if (isWindowsOS()) {
    document.body.classList.add("isWindowsOS");
  }

  return (
    <div className="fintech_wrapper">
      {openWaitListForm && <WaitListForm onClose={() => setOpenWaitListForm(false)} />}
      {openModalText && <ModalText onClose={() => setOpenModalText(false)} />}
      <Header setOpenWaitListForm={setOpenWaitListForm} />
      <Routes>
        <Route exact path={FM_LINKS.HOME} element={<Home />} />
        <Route exact path={FM_LINKS.HOTEL} element={<Hotel />} />
        <Route exact path={FM_LINKS.AGENDA} element={<Agenda />} />
        <Route exact path={FM_LINKS.COOKIES} element={<Cookies />} />

        <Route exact path={FM_LINKS.SCHEDULE} element={<Schedule />} />
        <Route exact path={FM_LINKS.SPONSORS} element={<Sponsors />} />
        <Route exact path={FM_LINKS.FEEDBACK} element={<Feedback />} />
        <Route exact path={FM_LINKS.SPEAKERS} element={<Speakers />} />

        <Route exact path={FM_LINKS.TICKETS} element={<TicketsPage />} />
        <Route exact path={FM_LINKS.SPEAKERS} element={<SpeakerForm />} />
        <Route exact path={FM_LINKS.TERMS_OF_USE} element={<TermsOfUse />} />
        <Route exact path={FM_LINKS.SPONSORS_FORM} element={<SponsorForm />} />
        
        <Route exact path={FM_LINKS.CO_LOCATED_EVENTS} element={<CoLocatedEvents />} />
        <Route exact path={FM_LINKS.APPLY_TO_SPEAK_FORM} element={<ApplyToSpeakForm />} />
        <Route exact path={FM_LINKS.TERMS_AND_CONDITIONS} element={<TermsAndConditions />} />
        <Route exact path={FM_LINKS.CO_LOCATED_EVENTS_FORM} element={<CoLocatedEventsForm />} />
        <Route exact path={`${FM_LINKS.SPEAKERS}/:speakerId`} element={<IndividualSpeaker />} />
        
        <Route
          exact
          path={FM_LINKS.EXHIBITOR_RULES_AND_REGULATIONS}
          element={<ExhibitorRulesAndRegulations />}
        />
        <Route
          exact
          path={FM_LINKS.TERMS_OF_PARTICIPATION}
          element={<TermsOfAttendanceAndParticipation />}
        />
        <Route
          exact
          path={FM_LINKS.HOSTED_TERMS_AND_CONDITIONS}
          element={<HostedTermsAndConditions />}
        />
        <Route path="*" element={<Navigate to={{ pathname: FM_LINKS.HOME }} />} />
      </Routes>
      <Footer setOpenWaitListForm={setOpenWaitListForm} />
    </div>
  );
}

export default App;
