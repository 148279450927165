import React from "react";
import { useCopy } from "utility/copyContext";
import useWindowScrollTop from "hooks/useWindowScrollTop";

export default function Agenda() {
  useWindowScrollTop();
  const { agenda } = useCopy();
  return (
    <main>
    <div className="container">
      <h1 className="static_pageTitle text-center" dangerouslySetInnerHTML={{ __html: agenda.title }}>
      
      </h1>
      <div className="static_page_contentHolder"> 
        {agenda.description.map((desc, idx) => {
          return <p key={idx} dangerouslySetInnerHTML={{ __html: desc }} />;
        })}
      </div>
    </div>
  </main>
  );
}
